import type { GameProduct, SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import { checkIfPragmaticJackpotPlayGame } from '@patrianna/shared-utils'

export const isGoldenKeyGameProduct = (gameProduct: GameProduct) =>
  gameProduct?.tags?.some((tag) => ['GCT', 'GCS'].includes(tag))

export const getNumberOfPreloadedItems = (
  { style, lines = 1 }: SwimlaneCategory,
  isMobile: boolean,
  isLandscape: boolean
) => {
  const multiplier = isMobile ? (isLandscape ? 3 : 1) : 2

  switch (style) {
    case 'heading': {
      return 1 + multiplier * 3 * lines + lines
    }
    case 'large': {
      return multiplier * 2 * lines + lines
    }
    case 'small': {
      return multiplier * 4 * lines + lines
    }
    default: {
      return multiplier * 3 * lines + lines
    }
  }
}

export const splitGamesByType = (allGames: GameProduct[]) => {
  return allGames.reduce(
    (result, game) => {
      if (checkIfPragmaticJackpotPlayGame(game)) {
        result.swimlane.push(game)
      } else {
        result.games.push(game)
      }

      return result
    },
    { swimlane: [], games: [] }
  )
}
